import { useEffect, useState } from 'react'
import react from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from "../customHooks/Translations";
import '../../styles/brokerSummary.css'
import { RootState } from '../../app/store';
import { confirmConnectionExecution, undoConfirmConnectionExecution, fetchConnectionAgreements, setSortProperty } from '../../features/connectionAgreements/connectionAgreementSlices';

export const BrokerSummary = () => {

  const dispatch = useDispatch();
  const translation = useTranslation();

  const userConnectionAgreements = useSelector((state: RootState) => state.connectionAgreements.connectionAgreements);
  const { isLoading, error, lastAction } = useSelector((state: RootState) => state.connectionAgreements);
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const [selectedAgreements, setSelectedAgreements] = useState([]);
  const [selectedSecurities, setSelectedSecurities] = useState([]);

  const [time, setTime] = useState(Date.now());

    useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 10000);
    return () => {
        clearInterval(interval);
    };
    }, []);

    useEffect(() => {
        dispatch(fetchConnectionAgreements())
    }, [time])

  const equitiesList = useSelector((state: RootState) => state.equities);

  useEffect(() => {
    dispatch(fetchConnectionAgreements());
  }, [dispatch]);

  useEffect(() => {
    if(lastAction === 'confirmationSuccess'){
        dispatch(fetchConnectionAgreements());
    } else if(lastAction === 'undoConfirmationSuccess'){
        dispatch(fetchConnectionAgreements());
    }
  }, [lastAction]);

  let emptyRows = 0;
  if(userConnectionAgreements?.length < 8){
    emptyRows = 8-userConnectionAgreements.length;
  }

  function getSecurityName (securityId) {
    const matchingEquity = equitiesList.equities.find(equity => equity.equityId === securityId)
    return (matchingEquity.ric + ' - ' + (translation.language === "En" ? matchingEquity.name : matchingEquity.localName));
  }

  function updateSelected() {
    const allSecurities = document.getElementsByName('securityCheck');
    const newSelectedSecurities = [];
    const newSelectedAgreements = [];
    for(var i = 0; i < allSecurities.length; i++){
        if((allSecurities[i] as HTMLInputElement).checked){
            const connectionAgreement = userConnectionAgreements.find(agreement => agreement.id === allSecurities[i].id);
            newSelectedAgreements.push(connectionAgreement);
            newSelectedSecurities.push(equitiesList.equities.find(equity => equity.equityId === connectionAgreement?.securityUUID)?.ric);
        }
    }
    setSelectedSecurities(newSelectedSecurities);
    setSelectedAgreements(newSelectedAgreements);
  }

  return (
    <div className='broker-summary-main'>
        <div className='broker-summary-main-header'>{translation.summaryScreen} | {userInfo?.organization_name || 'Broker 0'}</div>
        <div className='broker-summary-table'>
            <div className='broker-summary-table-header'>
            <div className='agreement-entry-index-header'>#</div>
            <div className='agreement-entry-security-header'>{translation.security}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'securityName'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'securityName'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            <div className='agreement-entry-quantity-header'>{translation.quantity}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'locationQuantity'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'locationQuantity'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            <div className='agreement-entry-price-header'>{translation.price}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'midPrice'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'midPrice'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            <div className='agreement-entry-type-header'>{translation.type}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'connectionAgreementType'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'connectionAgreementType'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            <div className='agreement-entry-password-header'>{translation.buy + ' ' + translation.password}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'buyPw'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'buyPw'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            <div className='agreement-entry-status-header'>{translation.buy + ' ' + translation.status}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'buyStatus'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'buyStatus'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            <div className='agreement-entry-password-header'>{translation.sell + ' ' + translation.password}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'sellPw'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'sellPw'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            <div className='agreement-entry-status-header'>{translation.sell + ' ' + translation.status}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'sellStatus'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'sellStatus'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            <div className='agreement-entry-timestamp-header'>{translation.timestamp}
            <div className='up-down-sort-container'>
                <div className='triangle-container sort-direction' onClick={() => {dispatch(setSortProperty(1, 'locateTime'))}}><div className='up-triangle'></div></div>
                <div className='triangle-container' onClick={() => {dispatch(setSortProperty(-1, 'locateTime'))}}><div className='down-triangle'></div></div>
            </div>
            </div>
            </div>
            <div className='broker-summary-table-entries'>
            {userConnectionAgreements?.map((connectionAgreement,index) => (
                <div className="broker-summary-entry" key={index}>
                    <div className='agreement-entry-index'>{index+1}</div>
                    <div className='agreement-entry-security'>{getSecurityName(connectionAgreement.securityUUID)}</div>
                    <div className='agreement-entry-security-checkbox'>
                    <input type={'checkbox'} name='securityCheck' onChange={updateSelected} checked={selectedAgreements.findIndex(agreement => agreement.id === connectionAgreement.id) !== -1}  id={connectionAgreement.id}/>
                    <span className="checkmark-broker"></span>
                    </div>
                    <div className='agreement-entry-quantity'>{connectionAgreement.locationQuantity}</div>
                    <div className='agreement-entry-price'>{connectionAgreement.midPrice}</div>
                    <div className='agreement-entry-type'>{(connectionAgreement.connectionAgreementType?.toUpperCase() === 'CASH_ONLY' ? translation.transactionTypeCASH_ONLY: connectionAgreement.connectionAgreementType?.toUpperCase() === 'SWAP_ONLY' ? translation.transactionTypeSWAP_ONLY : translation.transactionTypeCASH_SWAP)}</div>
                    <div className='agreement-entry-password'>{connectionAgreement.buyPw}</div>
                    <div className='agreement-entry-status-column'>
                        <div className={'agreement-entry-status-div ' + ((connectionAgreement.buyStatus ? "Received": "Pending"))} onClick={() => {if(!connectionAgreement.buyStatus){ dispatch(confirmConnectionExecution(connectionAgreement.id, true, !(connectionAgreement?.buyStatus)))} else {dispatch(undoConfirmConnectionExecution(connectionAgreement.id, true, !(connectionAgreement?.buyStatus)))}} }>{((connectionAgreement.buyStatus ? translation.brokerReceived: translation.brokerPending))}</div>
                    </div>
                    <div className='agreement-entry-password'>{connectionAgreement.sellPw}</div>
                    <div className='agreement-entry-status-column'>
                        <div className={'agreement-entry-status-div ' + ((connectionAgreement.sellStatus ? "Received": "Pending"))} onClick={() => {if(!connectionAgreement.sellStatus){ dispatch(confirmConnectionExecution(connectionAgreement.id, false, !(connectionAgreement?.sellStatus)))} else {dispatch(undoConfirmConnectionExecution(connectionAgreement.id, false, !(connectionAgreement?.sellStatus)))} } }>{((connectionAgreement.sellStatus ? translation.brokerReceived: translation.brokerPending))}</div>
                    </div>
                    <div className='agreement-entry-timestamp'>{new Date(Date.parse((connectionAgreement.locateTime + " UTC").replace(/-/g, "/"))).toLocaleString([], { hourCycle: 'h23'})}</div>
                </div>
            ))}
            {Array.apply(null, { length: emptyRows }).map((e, i) => (
                <div className="broker-summary-entry" key={i + (userConnectionAgreements?.length+1 || 1)}>
                    <div className='agreement-entry-index'>{i + (userConnectionAgreements?.length+1 || 1)}</div>
                    <div className='agreement-entry-security'></div>
                    <div className='empty-row-checkbox-space'></div>
                    <div className='agreement-entry-quantity'></div>
                    <div className='agreement-entry-price'></div>
                    <div className='agreement-entry-type'></div>
                    <div className='agreement-entry-password'></div>
                    <div className='agreement-entry-status-column'>
                        <div className={'agreement-entry-status-div ' }></div>
                    </div>
                    <div className='agreement-entry-password'></div>
                    <div className='agreement-entry-status-column'>
                        <div className={'agreement-entry-status-div '}></div>
                    </div>
                    <div className='agreement-entry-timestamp'></div>
                </div>
            ))}
            </div>
            <div className='broker-summary-table-footer'>
                {selectedSecurities.length > 0 ? <><span>{selectedSecurities.length} {translation.securitiesSelected}</span><span> | {selectedSecurities.toString()} {translation.clickCopyLine}</span></> : null}
            </div>
        </div>
    </div>
    )
}

export default BrokerSummary