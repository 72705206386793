import '../../styles/stockSelector.css';

import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Equity } from '../../app/model/model';
import {
  RootState,
  useAppDispatch,
} from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import { setRefresh } from '../../features/adverts/advertisementActions';
import {
  createAdvertisement,
} from '../../features/adverts/advertisementSlices';

export const StockSearchBar = props => {

  const dispatch = useAppDispatch()
  const translation = useTranslation();

  const equitiesList = useSelector((state: RootState) => state.equities);
  const userAdvertisements = useSelector((state: RootState) => state.advertisements.advertisements);
  const advertLastAction = useSelector((state: RootState) => state.advertisements.lastAction);
  const [dropdownActiveIndex, setDropdownActiveIndex] = useState(-1)
  const [focused, setFocused] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedEquity, setSelectedEquity] = useState<Equity | null>(null);

  const filteredEquities = useMemo(() => {
    const searchInputValue = searchInput.trim();
    if (!searchInputValue) {
      return equitiesList?.equities ?? [];
    }
    const filtered = equitiesList?.equities?.filter(equity => {
      const text = `${equity.ric} - ${equity.name} - ${equity.localName}`;
      return text?.toUpperCase().indexOf(searchInputValue.toUpperCase()) > -1
    }) ?? [];
    return filtered.slice(0, 10);
  }, [equitiesList?.equities, searchInput]);

  const showDropdown = searchInput.trim() && focused && !selectedEquity;

  async function submitEquity(equity: Equity) {
    setSelectedEquity(equity);
    let retVal: boolean | void;
    const equityIOIs = userAdvertisements.filter(ad => ad.securityId === equity.equityId);
    if(equityIOIs.length <= 0 || equityIOIs?.findIndex(ad => ad?.advertisementStatus?.toUpperCase() !== 'COMPLETED') === -1){
      retVal = dispatch(createAdvertisement(equity.equityId));
    } else{
      dispatch(setRefresh());
      retVal = true;
    }
    props.setSelectedId(equity.equityId);
    setSearchInput('');
    //if(props.container === "minimal" && retVal){
    //  props.setContainerState("basic")
    //}
  }

  useEffect(() => {
    if (advertLastAction === 'createSuccess' && props.container === "minimal") {
      props.setContainerState("basic");
    }
  }, [advertLastAction])

  function traverseList(direction: -1 | 1) {
    if (!showDropdown) {
      return;
    }
    setDropdownActiveIndex((prev) => {
      if ((prev + direction) <= -1 || (prev + direction) >= filteredEquities.length) {
        return direction === 1 ? 0 : filteredEquities.length - 1;
      }
      return prev + direction;
    });
  }

  return (
      <div className={`stock-search-bar ${props.container}`}>
        <div id="icon_search_sr" className="icon_search">
          <svg className="icon_search_ss" viewBox="0 0 16 16">
            <path id="icon_search_ss" d="M 12.69999980926514 11.22981357574463 C 13.59999942779541 10.03726673126221 14.09999942779541 8.645962715148926 14.09999942779541 7.055900573730469 C 14.10000038146973 3.180124044418335 11 0 7.099999904632568 0 C 3.199999809265137 0 0 3.180124044418335 0 7.055900096893311 C 0 10.93167686462402 3.200000047683716 14.11180019378662 7.099999904632568 14.11180019378662 C 8.699999809265137 14.11180019378662 10.19999980926514 13.61490631103516 11.29999923706055 12.72049617767334 L 14.29999923706055 15.70186328887939 C 14.49999904632568 15.90062046051025 14.79999923706055 16 14.99999904632568 16 C 15.19999885559082 16 15.49999904632568 15.90062046051025 15.69999885559082 15.70186328887939 C 16.09999847412109 15.30434799194336 16.09999847412109 14.70807456970215 15.69999885559082 14.31055927276611 L 12.69999980926514 11.22981357574463 Z M 7.099999904632568 12.0248441696167 C 4.300000190734863 12.0248441696167 2 9.838508605957031 2 7.055900096893311 C 2 4.273292064666748 4.300000190734863 1.987577557563782 7.099999904632568 1.987577557563782 C 9.899999618530273 1.987577557563782 12.19999980926514 4.273292064666748 12.19999980926514 7.055900096893311 C 12.19999980926514 9.838508605957031 9.899999618530273 12.0248441696167 7.099999904632568 12.0248441696167 Z">
            </path>
          </svg>
        </div>
        <div id="Start_by_typing_a_stock_to_adv_sq">
          <input
            type="Text"
            id='searchInput'
            autoComplete="off"
            autoFocus
            tabIndex={1}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={(ev) => {
              if (ev.code === "Enter") {
                if (dropdownActiveIndex < 0 || dropdownActiveIndex >= filteredEquities.length) {
                  return;
                }
                const activeEquity = filteredEquities[dropdownActiveIndex]
                submitEquity(activeEquity)
              } else if (ev.code === 'ArrowUp') {
                ev.preventDefault()
                traverseList(-1)
              } else if (ev.code === 'ArrowDown') {
                ev.preventDefault()
                traverseList(1)
              }
            }}
            value={searchInput}
            onChange={({ target: { value } }) => {
              if (value.trim() !== searchInput) {
                setSelectedEquity(null)
              }
              setSearchInput(value);
            }}
            placeholder={translation.startStock}
          />
        </div>
        <div
          id="Component_42__2_n"
          className="Component_42___2"
          onClick={() => {
            if (selectedEquity !== null) {
              submitEquity(selectedEquity);
            }
          }}
        >
          <svg id='search_arrow_container' viewBox="0 0 448 512">
            <path id="search_arrow" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
          </svg>
        </div>
        {showDropdown
          ? (
            <div
              className='stock-search-bar-dropdown'
              style={{ display: 'block' }}
            >
              {filteredEquities?.map((equity, index) => (
                <span
                  style={{
                    fontWeight: dropdownActiveIndex === index ? "bold" : null,
                    color: dropdownActiveIndex === index ? '#0D1265': null,
                  }}
                  key={`equity-${index}`}
                  onMouseDown={(event) => {
                    event.preventDefault()
                  }}
                  onClick={() => {
                    submitEquity(equity);
                  }}
                >
                  {translation.language === "En" ? `${equity.ric} - ${equity.name}`: `${equity.ric} - ${equity.localName}`}
                  
                </span>
              ))}
            </div>
          )
          : null
        }
      </div>
  )
}

StockSearchBar.propTypes = {
  setContainerState: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired,
  setSelectedId: PropTypes.func.isRequired
};

export default StockSearchBar