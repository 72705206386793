import '../../styles/IOIpopups.css';

import React, {
  useEffect,
  useState,
} from 'react';

import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { TransactionType } from '../../app/model/enums';
import { RootState } from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';

export type BuySellUpper = 'BUY' | 'SELL';
export type BuySellLower = 'buy' | 'sell';

type AcceptNotification = {
  direction: BuySellUpper | BuySellLower;
  securityId: string;
  brokerId: string;
  brokerName: string;
  password: string;
  locateTime: string;
  transactionType: TransactionType;
  residualPossible: boolean;
  locationQuantity: string;
  midPrice: string;
};

type IOIConnectionAcceptedProps = {
  notification?: AcceptNotification;
  closeNotification: (notification: AcceptNotification, residual?: string) => void;
};

export const IOIConnectionAccepted = ({
  notification,
  closeNotification,
}: IOIConnectionAcceptedProps) => {

  const translation = useTranslation();

  const [direction, setDirection] = useState<BuySellLower>('buy');
  const equitiesList = useSelector((state: RootState) => state.equities);
  const brokersList = useSelector((state: RootState) => state.brokers);
  const [notificationEquityName, setNotificationEquityName] = useState('');
  const [notificationEquityRic, setNotificationEquityRic] = useState('');
  const [brokerName, setBrokerName] = useState('');


  useEffect(() => {
    const notificationDirection = notification?.direction?.toLowerCase() as BuySellLower;
    setDirection(notificationDirection || 'buy');
    const correctEquity = equitiesList.equities.find(equity => equity.equityId === notification?.securityId);
    setNotificationEquityName(translation.language === "En" ? `${correctEquity.ric} - ${correctEquity.name}`: `${correctEquity.ric} - ${correctEquity.localName}`);
    setNotificationEquityRic(correctEquity?.ric);
    setBrokerName(notification?.brokerName)
  }, [brokersList.brokers, equitiesList.equities, notification])

  function copyPassword() {
    navigator.clipboard.writeText(notification?.password).then(() => alert(translation.copied));
  }

  const locateTimeFormatted = new Date((notification?.locateTime + ' UTC').replace(/-/g, "/")).toLocaleTimeString();

  function copyAgreementContent() {
    const content = `
      ${direction === 'buy' ? translation.buy: translation.sell} ${notificationEquityRic}
      ${translation.commonQuantity}: ${document.getElementById('commonQuantity-value').textContent}
      ${translation.midPrice}: ${document.getElementById('midPrice-value').textContent}
      ${translation.connectionTime}: ${locateTimeFormatted}
      ${translation.password}: ${notification?.password}
      ${translation.executingBroker}: ${brokerName}
    `;
    navigator.clipboard
      .writeText(content.trim().replace(/(\n)\s+/g, '$1'))
      .then(() => alert(translation.copied));
  }

  return (
    <div className='IOI-connection-accepted-popup'>
      <div className='IOI-connection-accepted-top'>{translation.connectionAccepted}</div>
      <div className='IOI-connection-accepted-body'>
        <div className='IOI-accepted-security-locate'>{notificationEquityName}</div>
        <div className='IOI-connection-details'>
            <div className='IOI-connection-detail-div'>
                <span className='IOI-connection-detail-label'>
                  {translation.ioiDirection}:
                </span>
                <span className={`IOI-connection-detail-value-${direction}`}>
                  {direction === 'buy' ? translation.buy: translation.sell}
                </span>
            </div>
            <div className='IOI-connection-detail-div'>
                <span className='IOI-connection-detail-label'>
                  {translation.commonQuantity}:
                </span>
                <span className={`IOI-connection-detail-value-${direction}`}>
                  <NumericFormat
                    id='commonQuantity-value'
                    displayType='text'
                    value={notification?.locationQuantity || '0'}
                    thousandSeparator=','
                    decimalScale={0}
                  />
                </span>
            </div>
            <div className='IOI-connection-detail-div'>
                <span className='IOI-connection-detail-label'>
                  {translation.transactionType}:
                </span>
                <span className={`IOI-connection-detail-value-${direction}`}>
                  {notification?.transactionType === 'CASH_ONLY'
                    ? translation.transactionTypeCASH_ONLY
                    : notification?.transactionType === 'SWAP_ONLY'
                      ? translation.transactionTypeSWAP_ONLY
                      : translation.transactionTypeCASH_SWAP
                  }
                </span>
            </div>
            <div className='IOI-connection-detail-div'>
                <span className='IOI-connection-detail-label'>
                  {translation.midPrice}:
                </span>
                <span className={`IOI-connection-detail-value-${direction}`}>
                  <NumericFormat
                    id='midPrice-value'
                    displayType='text'
                    value={notification?.midPrice || '0'}
                    thousandSeparator=','
                    decimalScale={3}
                  />
                </span>
            </div>
            <div className='IOI-connection-detail-div'>
                <span className='IOI-connection-detail-label'>
                  {translation.connectionTime}:
                </span>
                <span className={`IOI-connection-detail-value-${direction}`}>
                  {locateTimeFormatted}
                </span>
            </div>
        </div>
        <div className='IOI-connection-broker-details'>
            <div className='IOI-connection-detail-div'>
                <span className='IOI-connection-detail-label'>
                  {translation.password}
                </span>
                <span
                  className='IOI-connection-detail-value'
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {notification?.password}
                  {' '}
                  <img
                    alt='Copy Password'
                    onClick={copyPassword}
                    id="Group_38_hh_password"
                    src="Group_38_hh.png"
                    srcSet="Group_38_hh.png 1x, Group_38_hh@2x.png 2x"
                  />
                </span>
            </div>
            <div className='IOI-connection-detail-div'>
                <span className='IOI-connection-detail-label'>{translation.executingBroker}</span>
                <span className='IOI-connection-detail-value'>{brokerName}</span>
            </div>
        </div>

          <div className='IOI-connection-buttons'>
              <button
                className='connection-copy-details-button'
                onClick={copyAgreementContent}
              >
                <img
                  alt='Copy Details'
                  id="Group_38_hh"
                  src="Group_38_hh.png"
                  srcSet="Group_38_hh.png 1x, Group_38_hh@2x.png 2x"
                />
                <span>{translation.copyDetails}</span>
              </button>
              {notification.residualPossible ?
                <button
                  className='connection-residual-amount-button'
                  onClick={() => closeNotification(notification, 'residual')}
                >
                  {translation.residualAmount}
                </button>
              : null}
          </div>
        <button
          className='IOI-connection-close-button'
          onClick={() => closeNotification(notification)}
        >
          {translation.close}
        </button>
      </div>
    </div>
  )
}

export default IOIConnectionAccepted;
