import '../../styles/IOIbasic.css';
import '../../styles/IOIadvanced.css';
import '../../styles/containers.css';

import {
  useEffect,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import {
  connect,
  useSelector,
} from 'react-redux';

import {
  RootState,
  useAppDispatch,
} from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import {
  submitBrokerDefaults,
  submitPriceConditionDefaults,
} from '../../features/adverts/advertisementActions';
import {
  modifySelectAdvertisement,
} from '../../features/adverts/advertisementSlices';

export const IOIHome = props => {

  const translation = useTranslation();

  const dispatch = useAppDispatch();

  const equitiesList = useSelector((state: RootState) => state.equities);
  const numUserBrokers = useSelector((state:RootState) => state.auth.userInfo.numUserBrokers)
  const credibilityRating = useSelector((state: RootState) => state.auth.userInfo.credibilityRating);
  const brokersList = useSelector((state: RootState) => state.brokers.brokers);
  const advertAction = useSelector((state: RootState) => state.advertisements.lastAction);
  const isAfterHours = useSelector((state: RootState) => state.advertisements.afterHours);

  const [prevAction, setPrevAction] = useState(null);

  const [advancedTab, setAdvancedTab] = useState('price-conditions');

  const [toggleStates, setToggleStates] = useState([props.advert?.advertisingTriggerActivated || false,
    props.advert?.knockoutTriggerActivated || false,
    props.advert?.indexRelativeKOTriggerActivated || false]);
  const [credibilityFilterValue, setCredibilityFilterValue] = useState((Math.trunc((props.advert?.credibilityHurdle-1) / 20) + 1) || 0);
  const [quantityDecayOption, setQuantityDecayState] = useState(props.advert?.quantityDecay || false);
  const [minimumQstate, setMinimumQ] = useState(props.advert?.minimumQuantity || '50');
  const [rankingMode, setRankingMode] = useState(props.advert?.ranked || false);
  const [brokers, setBrokers] = useState(props.advert?.brokerIds || []);

  const [direction, setDirection] = useState(props.advert?.direction || '');
  const [durationPreference, setDurationPreference] = useState(props.advert?.duration || '150');

  const numberBrokersPerRow = 4

  useEffect(() => {
    if (((props.advert?.id && advertAction === 'select') || ((advertAction === 'modifySelected') && (prevAction === 'select' || prevAction === 'fetchSuccess')))) {
      const copyAdvert = {...props.advert};

      if (props.advert?.advertisementStatus === 'UNSENT') {
         const securityLastClose = getSecurityLastClose() || 0;
	       const tickSize = getTickSize();
         copyAdvert.buyAdvertisingTrigger = adjustTickSize((1 - copyAdvert.advertisingTrigger / 100) * securityLastClose, tickSize);
         copyAdvert.buyKnockoutTrigger = adjustTickSize((1 + copyAdvert.knockoutTrigger / 100) * securityLastClose, tickSize)

         copyAdvert.sellAdvertisingTrigger = adjustTickSize((1 + copyAdvert.advertisingTrigger / 100) * securityLastClose, tickSize)
         copyAdvert.sellKnockoutTrigger = adjustTickSize((1 - copyAdvert.knockoutTrigger / 100) * securityLastClose, tickSize)
      if (copyAdvert?.direction === 'BUY') {
            copyAdvert.advertisingTrigger = copyAdvert.buyAdvertisingTrigger;
            copyAdvert.knockoutTrigger = copyAdvert.buyKnockoutTrigger;
      	 } else {
            copyAdvert.advertisingTrigger = copyAdvert.sellAdvertisingTrigger;
            copyAdvert.knockoutTrigger = copyAdvert.sellKnockoutTrigger;
      	 }
      }

      dispatch(modifySelectAdvertisement(copyAdvert));
    } else if ((props.advert?.id && advertAction === 'submitPriceConditionDefaultsSuccess')) {
      alert(translation.prefsUpdated);
    } else if ((props.advert?.id && advertAction === 'submitBrokerDefaultsSuccess')) {
      alert(translation.prefsUpdated);
    }

    setPrevAction(advertAction);

    if (props.advert?.brokerIds.length === 0) {
      setAdvancedTab('path-preferences')
    } 
  }, [advertAction || props.advert])

  useEffect(() => {
    if(props.advert?.brokerIds){
      const brokersIdsList = brokersList.map(function getBrokerId(broker) {
        return broker.brokerID
      })
      
      // Filter out broker ids that may have been removed from the institution broker path.
      const filteredBrokerIds = props.advert.brokerIds.filter(function filterBrokers(brokerID) {
        return brokersIdsList.includes(brokerID)
      })

      // If brokers have been filtered out, set the brokers to that, otherwise the stateful value.
      setBrokers(filteredBrokerIds.length < props.advert.brokerIds.length ? filteredBrokerIds : props.advert.brokerIds)
    }

    if( (props.advert?.advertisingTriggerActivated || props.advert?.advertisingTriggerActivated === false) && (props.advert?.indexRelativeKOTriggerActivated || props.advert?.indexRelativeKOTriggerActivated === false) && (props.advert?.knockoutTriggerActivated || props.advert?.knockoutTriggerActivated === false)){
      setToggleStates([props.advert.advertisingTriggerActivated && !isAfterHours, props.advert.knockoutTriggerActivated && !isAfterHours, props.advert.indexRelativeKOTriggerActivated && !isAfterHours])
    }
    if(props.advert?.credibilityHurdle){
      setCredibilityFilterValue( Math.trunc((props.advert.credibilityHurdle-1) / 20) + 1 );
    }
    if(props.advert?.minimumQuantity) {
      setMinimumQ(props.advert.minimumQuantity);
    }
    setQuantityDecayState(props.advert?.quantityDecay || false);
    setRankingMode(props.advert?.ranked || false);
    setDirection(props.advert?.direction || '');

    setDurationPreference(props.advert?.duration || '150')
  }, [props.advert]);


  let addedClass = "-selling";

  if(direction === 'SELL'){
    addedClass = "-selling";
  }else{
    addedClass = "-buying";
  }

  function getDecimalPlaces(tickSize) {
    const decimal = (tickSize + "").split(".")
    const decimalPlaces = decimal.length > 1 ? decimal[1].length : 0

    return decimalPlaces
  }

  function adjustTickSize(value, tickSize) {
    const remainder = (value * 100) % (tickSize * 100) / 100
    value -= remainder

    value = value.toFixed(getDecimalPlaces(tickSize))

    return value
  }

  function buySellToggle() {
    var copyAdvert = {...props.advert};
    if(direction === 'BUY'){
      copyAdvert.direction = 'SELL'
      if (copyAdvert.sellAdvertisingTrigger != undefined)
         copyAdvert.advertisingTrigger = copyAdvert.sellAdvertisingTrigger;
      if (copyAdvert.sellKnockoutTrigger != undefined)
         copyAdvert.knockoutTrigger = copyAdvert.sellKnockoutTrigger;
      dispatch(modifySelectAdvertisement(copyAdvert));
    }else{
      copyAdvert.direction = 'BUY';
      if (copyAdvert.buyAdvertisingTrigger != undefined)
         copyAdvert.advertisingTrigger = copyAdvert.buyAdvertisingTrigger;
      if (copyAdvert.buyKnockoutTrigger != undefined)
         copyAdvert.knockoutTrigger = copyAdvert.buyKnockoutTrigger;
      dispatch(modifySelectAdvertisement(copyAdvert));
    }
  }

  function priceConditionsToggle(index) {
    if (!isAfterHours) {
      var copyAdvert = {...props.advert};
      switch(index) {
        case 0:
          if (credibilityRating >= 41 && (!copyAdvert.advertTriggerSatisfied || copyAdvert.advertisementStatus == 'DEAD')) {
             copyAdvert.advertisingTriggerActivated = !copyAdvert.advertisingTriggerActivated;
          }
          break;
        case 1:
          copyAdvert.knockoutTriggerActivated = !copyAdvert.knockoutTriggerActivated;
          break;
        case 2:
          copyAdvert.indexRelativeKOTriggerActivated = !copyAdvert.indexRelativeKOTriggerActivated;
          break;
        default:
          //Invalid Index
      }
      dispatch(modifySelectAdvertisement(copyAdvert));
    }
  }

  function updatePropMinimumQ() {
    var copyAdvert = {...props.advert};
    var percentSelect = parseInt((document.getElementById('minimum-q-input') as HTMLInputElement).value);
    if (percentSelect < 10) {
      percentSelect = 10;
    } else if (percentSelect > 75) {
      percentSelect = 75;
    }
    copyAdvert.minimumQuantity = (((percentSelect) * copyAdvert.shareQuantity) / 100 );
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updateMinimumQ() {
    var percentSelect = parseInt((document.getElementById('minimum-q-input') as HTMLInputElement).value);
    if (percentSelect < 10) {
      percentSelect = 10;
    } else if (percentSelect > 75) {
      percentSelect = 75;
    }
    setMinimumQ(((percentSelect) * props?.advert?.shareQuantity) / 100 );
  }

  function updateRanking() {
    var copyAdvert = {...props.advert};
    copyAdvert.ranked = (document.getElementById('rankedOption')as HTMLInputElement).checked
    dispatch(modifySelectAdvertisement(copyAdvert));
  }


  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, i) => {
    dragItem.current = i;
  };

  const dragEnter = (e, i) => {
    if(e.target.outerText !== '' && e.target.outerText !== undefined){
      dragOverItem.current = i;
    }
  };

  const drop = (e) => {
    const copyBrokers = [...brokers];
    copyBrokers[dragItem.current !== undefined ? dragItem.current : 0] = copyBrokers.splice(dragOverItem.current, 1, copyBrokers[dragItem.current !== undefined ? dragItem.current : 0])[0];
    setBrokers(copyBrokers);
  };

  function replaceBroker(originalBroker, newBroker) {
    const copyBrokers = [...brokers];
    const positionOfNewBroker = copyBrokers.indexOf(brokersList[newBroker].brokerID);
    if(positionOfNewBroker !== -1){
      copyBrokers[originalBroker] = copyBrokers.splice(positionOfNewBroker, 1, copyBrokers[originalBroker])[0];
    }else{
      copyBrokers[originalBroker] = brokersList[newBroker].brokerID;
    }
    setBrokers(copyBrokers);
  }

  function removeBroker(originalBroker) {
    const copyBrokers = [...brokers];
    copyBrokers.splice(originalBroker, 1);
    setBrokers(copyBrokers);
  }

  function addBroker(newBroker) {
    const copyBrokers= [...brokers];
    if(copyBrokers.indexOf(brokersList[newBroker].brokerID) === -1){
      copyBrokers.push(brokersList[newBroker].brokerID);
    }else{
      //already in list do not add again
    }
    setBrokers(copyBrokers);
  }

  useEffect(() => {
    var copyAdvert = {...props.advert};
    copyAdvert.brokerIds = brokers;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }, [brokers])

  function updateCredibilityFilterValue (newValue) {
    var copyAdvert = {...props.advert};
    if(newValue === 1){
      copyAdvert.credibilityHurdle = 10;
    }else{
      copyAdvert.credibilityHurdle = (((newValue-1)*20) + 1);
    }
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function getSecurityName () {
    if(props.advert?.securityId){
      const matchingEquity = equitiesList.equities.find(equity => equity.equityId === props.advert.securityId)
      return (matchingEquity.ric + ' - ' + (translation.language === "En"? matchingEquity.name : matchingEquity.localName));
    }
    return '';
  }

  function getSecurityLastClose () {
    if(props.advert?.securityId){
      const matchingEquity = equitiesList.equities.find(equity => equity.equityId === props.advert.securityId)
      return (matchingEquity.closePrice);
    }
    return '';
  }

  function getTickSize() {
    if(props.advert?.securityId){
      const matchingEquity = equitiesList.equities.find(equity => equity.equityId === props.advert.securityId)
      return (matchingEquity.tickSize);
    }
    return '';
  }

  function getSecurityMaxPrice () {
    if(props.advert?.securityId){
      const matchingEquity = equitiesList.equities.find(equity => equity.equityId === props.advert.securityId)
      return (matchingEquity.maxPrice);
    }
    return '';
  }

  function getSecurityMinPrice () {
    if(props.advert?.securityId){
      const matchingEquity = equitiesList.equities.find(equity => equity.equityId === props.advert.securityId)
      return (matchingEquity.minPrice);
    }
    return '';
  }

  function equalizeQuantities () {
    var copyAdvert = {...props.advert};
    const sharePrice = copyAdvert.usdnotional / copyAdvert.shareQuantity;
    const usdjpy = copyAdvert.usdnotional / copyAdvert.jpynotional;
    const matchingEquity = equitiesList.equities.find(equity => equity.equityId === props.advert?.securityId)
    if(matchingEquity !== undefined){
      if(copyAdvert.shareQuantity % matchingEquity.lotSize !== 0){
        if(copyAdvert.shareQuantity < matchingEquity.lotSize){
          copyAdvert.shareQuantity = matchingEquity.lotSize;
        }else{
          copyAdvert.shareQuantity = copyAdvert.shareQuantity - (copyAdvert.shareQuantity % matchingEquity.lotSize);
        }
        copyAdvert.minimumQuantity = ((minimumQstate / copyAdvert.shareQuantity)*(copyAdvert.shareQuantity));
        copyAdvert.currentQuantity = copyAdvert.shareQuantity;
        copyAdvert.quantityRemaining = copyAdvert.shareQuantity;
        copyAdvert.usdnotional = copyAdvert.shareQuantity*sharePrice;
        copyAdvert.jpynotional = copyAdvert.shareQuantity*(sharePrice/usdjpy);
      }
      dispatch(modifySelectAdvertisement(copyAdvert));
    }
  }

  function updateShareQuantity (floatValue) {
    var copyAdvert = {...props.advert};
    const sharePrice = copyAdvert.usdnotional / copyAdvert.shareQuantity;
    const usdjpy = copyAdvert.usdnotional / copyAdvert.jpynotional;
    copyAdvert.minimumQuantity = ((minimumQstate / copyAdvert.shareQuantity)*(floatValue));
    copyAdvert.shareQuantity = floatValue;
    copyAdvert.currentQuantity = copyAdvert.shareQuantity;
    copyAdvert.quantityRemaining = copyAdvert.shareQuantity;
    copyAdvert.usdnotional = floatValue*sharePrice;
    copyAdvert.jpynotional = floatValue*(sharePrice/usdjpy);
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updateusdNotional (floatValue) {
    var copyAdvert = {...props.advert};
    const sharePrice = copyAdvert.usdnotional / copyAdvert.shareQuantity;
    const usdjpy = copyAdvert.usdnotional / copyAdvert.jpynotional;
    copyAdvert.minimumQuantity = ((minimumQstate / copyAdvert.shareQuantity)*(floatValue/sharePrice));
    copyAdvert.shareQuantity = floatValue/sharePrice;
    copyAdvert.currentQuantity = copyAdvert.shareQuantity;
    copyAdvert.quantityRemaining = copyAdvert.shareQuantity;
    copyAdvert.usdnotional = floatValue;
    copyAdvert.jpynotional = floatValue/usdjpy;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updatejpyNotional (floatValue) {
    var copyAdvert = {...props.advert};
    const sharePrice = copyAdvert.usdnotional / copyAdvert.shareQuantity;
    const usdjpy = copyAdvert.usdnotional / copyAdvert.jpynotional;
    copyAdvert.minimumQuantity = ((minimumQstate / copyAdvert.shareQuantity)*(floatValue*(usdjpy/sharePrice)));
    copyAdvert.shareQuantity = floatValue*(usdjpy/sharePrice);
    copyAdvert.currentQuantity = copyAdvert.shareQuantity;
    copyAdvert.quantityRemaining = copyAdvert.shareQuantity;
    copyAdvert.usdnotional = floatValue*usdjpy;
    copyAdvert.jpynotional = floatValue;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updateQuantityDecay () {
    var copyAdvert = {...props.advert};
    copyAdvert.quantityDecay = !quantityDecayOption;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function setTransactionType ( transactionType ) {
    var copyAdvert = {...props.advert};
    copyAdvert.transactionType = transactionType;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function setTransactionTypePreference ( transactionType ) {
    var copyAdvert = {...props.advert};
    copyAdvert.transactionTypePreference = transactionType;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updateDuration ( newDuration ) {
    var copyAdvert = {...props.advert};
    copyAdvert.duration = newDuration;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updateAdvertisingTrigger (floatValue) {
    var copyAdvert = {...props.advert};
    copyAdvert.advertisingTrigger = floatValue;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updateKnockoutTrigger (floatValue) {
    var copyAdvert = {...props.advert};
    copyAdvert.knockoutTrigger = floatValue;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updateIndexRelativeKOTrigger (floatValue) {
    var copyAdvert = {...props.advert};
    copyAdvert.indexRelativeKOTrigger = floatValue;
    dispatch(modifySelectAdvertisement(copyAdvert));
  }

  function updatePriceConditionDefaults() {
    var copyAdvert = {...props.advert};
    dispatch(submitPriceConditionDefaults(copyAdvert));
  }

  function updateBrokerDefaults() {
    var copyAdvert = {...props.advert};
    dispatch(submitBrokerDefaults(copyAdvert));
  }

  return (
    <div className={'IOI-window-transitions IOI-'+props.container+'-window'}>
      <div className={'IOI-window-top '+props.container}>
        <span className='ioi-home-security-name'>
          {getSecurityName()}
        </span>
      </div>
      <div className={'IOI-window-body'+addedClass + ' ' + props.container}>
        <button className={'buy-sell-toggle'+addedClass} tabIndex={2} id='buy-sell-toggle-button' onClick={buySellToggle}>
            <div className={'sell'+addedClass}>{translation.sell}</div>
            <div className={'buy'+addedClass}>{translation.buy}</div>
            <div className={'toggle-background'+addedClass}></div>
        </button>
        <div className={'quantity'+addedClass}>
            <div className='quantity-title prevent-select'>{translation.quantity}:</div>
            <div className={'quantity-field'+addedClass}><NumericFormat id='quantity-field-input' isAllowed={(values) => {const { floatValue } = values; return floatValue > 0;}} decimalScale={2} value={props.advert?.shareQuantity} onValueChange={(values) => {updateShareQuantity(values.floatValue)}} onBlur={() => {equalizeQuantities()}} thousandSeparator=','/></div>
        </div>
        <div className='usd'>
            <div className='usd-title prevent-select'>{translation.usdNotional}:</div>
            <div className='usd-field'><NumericFormat id='usd-field-input' isAllowed={(values) => {const { floatValue } = values; return floatValue > 0;}} decimalScale={0} value={props.advert?.usdnotional} onValueChange={(values) => {updateusdNotional(values.floatValue)}} onBlur={() => {equalizeQuantities()}} thousandSeparator=','/></div>
        </div>
        <div className='jpy'>
            <div className='jpy-title prevent-select'>{translation.jpyNotional}:</div>
            <div className='jpy-field'><NumericFormat id='jpy-field-input' isAllowed={(values) => {const { floatValue } = values; return floatValue > 0;}} decimalScale={0} value={props.advert?.jpynotional} onValueChange={(values) => {updatejpyNotional(values.floatValue)}} onBlur={() => {equalizeQuantities()}} thousandSeparator=','/></div>
        </div>
        <div className='transaction-type prevent-select'>
            <div className='transaction-type-title'>{translation.transactionType}:</div>
            <div className='transaction-type-dropdown'>
              <span id='transaction-type-option' style={translation.language === 'Jp' && props.advert?.transactionType === 'CASH_SWAP' ? {width: '50%', textAlign: 'left', whiteSpace: 'nowrap', fontSize: 'calc(8*var(--responsiveFont))' }: {width: '50%', textAlign: 'left', whiteSpace: 'nowrap'}}>
                {props.advert?.transactionType === 'CASH_SWAP' ? translation.transactionTypeCASH_SWAP : props.advert?.transactionType === 'SWAP_ONLY' ? translation.transactionTypeSWAP_ONLY : translation.transactionTypeCASH_ONLY}
              </span>
                <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                    <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                    </path>
                </svg>
            </div>
            <div className='transaction-type-options'>
                <span onClick={() => setTransactionType('CASH_ONLY')}>{translation.transactionTypeCASH_ONLY}</span>
                <span onClick={() => setTransactionType('SWAP_ONLY')}>{translation.transactionTypeSWAP_ONLY}</span>
                <span onClick={() => setTransactionType('CASH_SWAP')}>{translation.transactionTypeCASH_SWAP}</span>
            </div>
        </div>
      </div>
        {props.container === 'advanced' ?
            <div className={'IOI-window-advanced'+(props.container !== 'advanced' ? '' :'-expand')+addedClass}>
            <div className='IOI-window-advanced-options prevent-select'>
            <div className='plus-icon advanced'  onClick={() => props.setContainerState("basic")}>
                <img id="Group_20_bh" src="plus-button-mask.png"/>
            </div>
            <div className={'advanced-options-menu ' + props.container}>
                <div className={'price-conditions '+ (advancedTab === 'price-conditions' ? 'selected': '')} onClick={() => setAdvancedTab("price-conditions")}>{translation.priceConditions}</div>
                <div className={'advertising-preferences '+ (advancedTab === 'advertising-preferences' ? 'selected': '')} onClick={() => setAdvancedTab("advertising-preferences")}>{translation.advertisingPreferences}</div>
                <div className={'path-preferences '+ (advancedTab === 'path-preferences' ? 'selected': '')} style={brokers.length === 0 ? {background: 'red'} : null} onClick={() => setAdvancedTab("path-preferences")}>{translation.pathPreferences}</div>
            </div>
        </div>
        {
        {
          'price-conditions':
          <div className='price-conditions-menu'>
            <div className='price-condition-option-labels'>
                <div className='price-condition-option-label'>{translation.advertisingTrigger}</div>
                <div className='price-condition-option-label'>{translation.cancelTrigger}</div>
                <div className='price-condition-option-label'>{translation.indexRelativeCancel}</div>
            </div>
            <div className='price-condition-option-toggle-container'>
              {toggleStates.map((toggleBool, index) => (
                <div key={'priceConditionsToggle'+index} onClick={() => priceConditionsToggle(index)} className={toggleBool?'price-condition-option-toggle-active':'price-condition-option-toggle'}>
                  <div className='toggle-circle'></div>
                </div>
              ))}
            </div>
            <div className='price-condition-option-value-container'>
                <div className={toggleStates[0] ? 'price-condition-option-value-active' : 'price-condition-option-value-inactive'}>
                  <NumericFormat disabled={!toggleStates[0]} className='price-conditions-field-input' id='ad-trigger-field-input' onValueChange={(values) => {updateAdvertisingTrigger(values.floatValue)}} decimalScale={getDecimalPlaces(getTickSize())} value={props.advert?.advertisingTrigger} thousandSeparator=',' prefix='JPY ' allowNegative={false}/>
                </div>
                <div className={toggleStates[1] ? 'price-condition-option-value-active' : 'price-condition-option-value-inactive'}>
                  <NumericFormat disabled={!toggleStates[1]} className='price-conditions-field-input' id='knockout-trigger-field-input' decimalScale={getDecimalPlaces(getTickSize())} onValueChange={(values) => {updateKnockoutTrigger(values.floatValue)}} value={props.advert?.knockoutTrigger} thousandSeparator=',' prefix='JPY ' allowNegative={false}/>
                </div>
                <div className={toggleStates[2] ? 'price-condition-option-value-active' : 'price-condition-option-value-inactive'}>
                <NumericFormat disabled={!toggleStates[2]} className='price-conditions-field-input' id='index-relative-ko-trigger-field-input' onValueChange={(values) => {updateIndexRelativeKOTrigger(values.floatValue)}} decimalScale={getDecimalPlaces(getTickSize())} value={props.advert?.indexRelativeKOTrigger} thousandSeparator=',' suffix='%' allowNegative={false} />
                </div>
            </div>
            <div className='price-condition-variables'>
                <div className='price-condition-variable' style={{fontWeight: 'bold'}}>{translation.tradingRange}</div>
                <div className='price-condition-variable'>{translation.maxPrice}: {getSecurityMaxPrice()}</div>
                <div className='price-condition-variable'>{translation.minPrice}: {getSecurityMinPrice()}</div>
            </div>
          </div>,
          'advertising-preferences':
          <div className='advertising-preferences-menu'>
            <div className='advertising-preferences-options'>
                <div className='credibility-filter-menu prevent-select'>
                  <div className='advertising-preferences-option-label'>{translation.credibilityFilter}</div>
                  <div className='credibility-filter-value'>
                    {Array.apply(null, { length: credibilityFilterValue}).map((e, i) => (
                      <img key={'credbilityFilter'+i} onClick={() => updateCredibilityFilterValue(i+1)} id="Group_11" src="Group_11.png" srcSet="Group_11.png 1x, Group_11@2x.png 2x"></img>
                    ))}
                    {Array.apply(null, { length: 5 - credibilityFilterValue }).map((e, i) => (
                      <svg key={'credbilityFilter'+(credibilityFilterValue + i)} onClick={() => updateCredibilityFilterValue(credibilityFilterValue+i+1)} className="Path_315_xl" viewBox="1.075 1.13 23.277 22.138">
                      <path id="Path_315_xl" d="M 12.71335887908936 1.12969970703125 L 16.30998229980469 8.417112350463867 L 24.35202026367188 9.585659027099609 L 18.53284645080566 15.2583179473877 L 19.9066047668457 23.26806831359863 L 12.71335887908936 19.48629760742188 L 5.520115375518799 23.26806831359863 L 6.89387321472168 15.2583179473877 L 1.074699401855469 9.585659027099609 L 9.116737365722656 8.417112350463867 L 12.71335887908936 1.12969970703125 Z">
                      </path>
                      </svg>
                    ))}
                  </div>
                </div>
                <div className='duration-menu prevent-select'>
                  <div className='advertising-preferences-option-label'>{translation.duration}:</div>
                  <div className='duration-option-dropdown'>
                    <span id='duration-option-text' style={{width: '75%', textAlign: 'left'}}>
                      {durationPreference === 'END_OF_DAY' ? translation.goodForTheDay : durationPreference + ' ' + translation.minutes}
                    </span>
                    <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                      <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                      </path>
                    </svg>
                  </div>
                  <div className='transaction-type-option-options'>
                    <span onClick={(e) => updateDuration('60')}>60 {translation.minutes}</span>
                    <span onClick={(e) => updateDuration('150')}>150 {translation.minutes}</span>
                    <span onClick={(e) => updateDuration('END_OF_DAY')}>{translation.goodForTheDay}</span>
                  </div>
                </div>
                <div className='minimum-Q-menu'>
                  <div className='advertising-preferences-option-label prevent-select'>{translation.minimumQ}:</div>
                  <div className='minimum-q-slider'>
                    <input style={{background: 'linear-gradient(to right,#0D1265 0%,#0D1265 '+(minimumQstate/props?.advert?.shareQuantity * 100).toFixed(0)+'%,#D6D6D6 '+(minimumQstate/props?.advert?.shareQuantity * 100).toFixed(0)+'%,#D6D6D6 100%)'}}
                    type="range" id="minimum-q-input" onChange={updateMinimumQ} onMouseUp={updatePropMinimumQ} name="minimum-q" min="0" max="100" step={1} value={(minimumQstate/props?.advert?.shareQuantity * 100).toFixed(0)} className="minimum-q-slider-input" />
                    <div style={{left: 'calc(('+(minimumQstate/props?.advert?.shareQuantity * 100).toFixed(0)+'% * 0.9) + -7%)'}} className='minimum-q-slider-input-hover-value'>
                      {(minimumQstate/props?.advert?.shareQuantity * 100).toFixed(0)}%
                    </div>
                  </div>
                </div>
                <div className='quantity-decay-menu'>
                  <div className='advertising-preferences-option-label prevent-select'>{translation.quantityDecay}</div>
                  <div className='quantity-decay-toggle-container'>
                  <div onClick={updateQuantityDecay} className={quantityDecayOption?'quantity-decay-option-toggle-active':'quantity-decay-option-toggle'}>
                    <div className='toggle-circle'></div>
                  </div>
                  </div>
                </div>
                <div className='transaction-type-menu prevent-select'>
                  <div className='advertising-preferences-option-label'>{translation.transactionType}:</div>
                  <div className='transaction-type-option-dropdown'><span id='transaction-type-option-preference' style={translation.language === 'Jp' && props.advert?.transactionTypePreference === 'CASH_SWAP' ? {width: '50%', textAlign: 'left', whiteSpace: 'nowrap', fontSize: 'calc(8*var(--responsiveFont))' }: {width: '50%', textAlign: 'left', whiteSpace: 'nowrap'}}>
                    {props.advert?.transactionTypePreference === 'CASH_ONLY' ? translation.transactionTypeCASH_ONLY : props.advert?.transactionTypePreference === 'SWAP_ONLY' ? translation.transactionTypeSWAP_ONLY : translation.transactionTypeCASH_SWAP}
                    </span>
                    <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                      <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                      </path>
                    </svg>
                  </div>
                  <div className='transaction-type-option-options'>
                      <span onClick={(e) => setTransactionTypePreference('CASH_ONLY')}>{translation.transactionTypeCASH_ONLY}</span>
                      <span onClick={(e) => setTransactionTypePreference('SWAP_ONLY')}>{translation.transactionTypeSWAP_ONLY}</span>
                      <span onClick={(e) => setTransactionTypePreference('CASH_SWAP')}>{translation.transactionTypeCASH_SWAP}</span>
                  </div>
                </div>
            </div>
            <button className='set-default-button' onClick={() => {updatePriceConditionDefaults()}}>{translation.setDefault}</button>
          </div>,
          'path-preferences':
          <div className={numUserBrokers > numberBrokersPerRow ? 'path-preferences-menu-multi-row' : 'path-preferences-menu'}>
            <div className={numUserBrokers > numberBrokersPerRow ? 'path-preferences-options-multi-row' : 'path-preferences-options'}>
              <div className={numUserBrokers > numberBrokersPerRow ? 'rank-options-multi-row' : 'rank-options'} onChange={updateRanking}>
                <label className='rank-option'>{translation.ranked}
                  <input type={'radio'} name="rank-option-select" id='rankedOption' checked={rankingMode} onChange={updateRanking}/>
                  <span className='radioCheck'></span>
                </label>
                <label className='rank-option'>{translation.equalRank}
                  <input type={'radio'} name="rank-option-select" id='equalRankOption' checked={!rankingMode} onChange={updateRanking}/>
                  <span className='radioCheck'></span>
                </label>
              </div>
              <div className='broker-rank-row'>
                {brokers.length !== 0 ? brokers.map((userBroker, index) => 
                    <div className={numUserBrokers > numberBrokersPerRow ? 'broker-rank-multi-row' : 'broker-rank'} key={'broker-rank'+ index}>
                    <div className='broker-rank-label'><span>{rankingMode ? translation.rank + " "+(index+1):""}</span></div>
                    <div className='broker-option-drag-drop' style={brokersList.find(broker => broker.brokerID === userBroker) === undefined ? {opacity: 0.4} : {}} id={'broker '+ index}
                    draggable onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop}>
                      <div className='draggable-icon-container'>
                        <div className='draggable-icon-row'>
                          <div></div>
                          <div></div>
                        </div>
                        <div className='draggable-icon-row'>
                          <div></div>
                          <div></div>
                        </div>
                        <div className='draggable-icon-row'>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                      <span className='broker-option-text'>{brokersList.find(broker => broker.brokerID === userBroker)?.shortName || translation.noneSelected}</span>
                      <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                        <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                        </path>
                      </svg>
                    </div>
                    <div className='broker-option-dropdown-menu'>
                      {brokersList.map((brokerInList, jindex) => (
                        <span key={'broker-option'+jindex} onClick={() => replaceBroker(index, jindex)}>{brokerInList.brokerName + ' | ' + brokerInList.shortName}</span>
                      ))}
                      <span onClick={() => removeBroker(index)}>- {translation.none} -</span>
                    </div>
                  </div>) : null}
                { Array.apply(null, { length: ((Math.trunc((brokersList.length - 1) / numberBrokersPerRow) + 1) * numberBrokersPerRow) - brokers.length}).map((e, i) => (
                  <div className={numUserBrokers > numberBrokersPerRow ? 'broker-rank-multi-row' : 'broker-rank'} key={'brokerEmpty'+(i+brokers.length)}>
                  <div className='broker-rank-label'><span>{rankingMode ? translation.rank + " "+(i+1+brokers.length):""}</span></div>
                  <div className='broker-option-drag-drop no-broker' style={{opacity: 0.4}} id={'broker '+(i+brokers.length)}>
                    <div className='draggable-icon-container'>
                      <div className='draggable-icon-row'>
                        <div></div>
                        <div></div>
                      </div>
                      <div className='draggable-icon-row'>
                        <div></div>
                        <div></div>
                      </div>
                      <div className='draggable-icon-row'>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <span className='broker-option-text'>{translation.noneSelected}</span>
                    <svg className='Path_26_f' viewBox="2.6 4.6 11 7">
                      <path id="Path_26_f" d="M 8.09999942779541 11.60000038146973 L 2.599999666213989 6.041176319122314 L 4.02592658996582 4.599999904632568 L 8.09999942779541 8.717647552490234 L 12.17407512664795 4.599999904632568 L 13.59999942779541 6.041176319122314 L 8.09999942779541 11.60000038146973 Z">
                      </path>
                    </svg>
                  </div>
                  <div className='broker-option-dropdown-menu'>
                    {brokersList.map((brokerInList, jindex) => (
                      <span key={'emptyBrokerOptions'+jindex} onClick={() => addBroker(jindex)}>{brokerInList.brokerName + ' | ' + brokerInList.shortName}</span>
                    ))}
                  </div>
                </div>
                ))}
              </div>
              {numUserBrokers > numberBrokersPerRow ? <button className='set-default-button-multi-row' onClick={() => {updateBrokerDefaults()}}>{translation.setDefault}</button>
              : null}
            </div>
              {numUserBrokers <= numberBrokersPerRow ? <button className='set-default-button' onClick={() => {updateBrokerDefaults()}}>{translation.setDefault}</button>
              : null}
          </div>
        }[advancedTab]
      }
      </div>
        :
        <div className={'prevent-select IOI-window-advanced'+(props.container !== 'advanced'? '' :'-expand')+addedClass}>
        <div className='plus-icon' onClick={() => props.setContainerState("advanced")}>
            <img id="Group_20_bh" src="plus-button-mask.png"/>
        </div>
        <div className='advanced-options-menu'>
            <div className='price-conditions' onClick={() => {setAdvancedTab("price-conditions"); props.setContainerState("advanced");}}>{translation.priceConditions}</div>
            <div className='advertising-preferences' onClick={() => {setAdvancedTab("advertising-preferences"); props.setContainerState("advanced");}}>{translation.advertisingPreferences}</div>
            <div className='path-preferences' style={brokers.length === 0 ? {background: 'red'} : null} onClick={() => {setAdvancedTab("path-preferences"); props.setContainerState("advanced");}}>{translation.pathPreferences}</div>
        </div>
        </div>
    }
    </div>
  )
}

IOIHome.propTypes = {
  setContainerState: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  // Map the relevant state from the Redux store to props
  return {
    advert: state.advertisements.selectedAdvertisement,
  };
};

export default connect(mapStateToProps)(IOIHome);
