import '../../styles/IOIpopups.css';

import React, {
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { RootState } from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';

export const MatchConfirmedPopup = props => {

    const translation = useTranslation();
    const dispatch = useDispatch();
    const [notificationEquityName, setNotificationEquityName] = useState('');
    const [transactionTypeText, setTransactionTypeText] = useState('');

    const equitiesList = useSelector((state: RootState) => state.equities);

    useEffect(() => {
      const correctEquity = equitiesList.equities.find(equity => equity.equityId === props.notification?.securityId);
      setNotificationEquityName(translation.language === "En" ? `${correctEquity.ric} - ${correctEquity.name}`: `${correctEquity.ric} - ${correctEquity.localName}`);
      if(props.notification?.transactionType === 'CASH_SWAP'){
        setTransactionTypeText(translation.transactionTypeCASH_SWAP)
      }else if(props.notification?.transactionType === 'SWAP_ONLY'){
        setTransactionTypeText(translation.transactionTypeSWAP_ONLY)
      }else{
        setTransactionTypeText(translation.transactionTypeCASH_ONLY)
      }

    }, [props.notification, translation.language])

  return (
    <div className='IOI-basic-popup'>
      <div className='IOI-review-top'>
        {translation.matchConfirmed}
      </div>
      <div className='match-confirmed-body'>
        <div className='IOI-accepted-security'>
          {notificationEquityName.slice(0, 24)}
        </div>
        {props.notification?.ack ?
        <button className='match-confirmed-close-button' onClick={() => {props.closeNotification(props.notification)}}>{translation.close}</button>
        :
        <button className='match-confirmed-close-button' onClick={() => {props.closeNotification(props.notification, 'ok')}}>{translation.ok}</button>
        }
      </div>
    </div>
  )
}

MatchConfirmedPopup.propTypes = {
    notification: PropTypes.object.isRequired,
    closeNotification: PropTypes.func.isRequired
};

export default MatchConfirmedPopup